<template>
  <v-select
          v-model="mystatus"
          :items="$store.state.budget.status"
          item-value="value"
          item-text="label"
          chips
          multiple
          label="berücksichtigter Status"
          outlined
          dense
          @change="statuschange"
        >
          <template #selection="{ item }">
            <v-chip small :color="item.color" class="white--text">{{
              item.label
            }}</v-chip>
          </template>
        </v-select>
</template>

<script>
export default {
    name: "StatusSelector",
    props: ["status"],
    data() {
      return {
        mystatus: [1,2]
      }
    },
    methods: {
      statuschange(){
        localStorage.setItem("fc_status", JSON.stringify(this.mystatus));
        this.$emit("change", this.mystatus); 
      }
    },
    created(){
      if (this.status) {
        this.mystatus = this.status;
      } else {
        let fc_status = localStorage.getItem("fc_status");
        if (fc_status) this.mystatus = JSON.parse(fc_status); 
      }
      
      this.$emit("change", this.mystatus); 
    }
}
</script>

<style>

</style>