<template>
  <v-dialog v-model="dialog" scrollable max-width="860px">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
        <small>{{ title }}</small>
      </v-card-title>
      <v-card-text>
        <div
          class="text-center"
          style="height: 400px; padding-top: 160px"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary lighten-4"
            size="100"
            width="10"
          ></v-progress-circular>
        </div>
        <v-container v-if="!loading">
          <v-banner color="warning" class="mb-2" v-if="moment">
            <p>
              <strong
                >Die Budgetzahlen sind in Bearbeitung und können sich bis zur
                Meldung noch ändern!</strong
              >
            </p>
            Die letzte Änderung muss sich auch nicht zwangsläufig auf das Income
            ausgewirkt haben, und kann z.B. nur einen Kommentar o.ä. betreffen.
            Daher ist es durchaus möglich, dass keine Änderungen ausgewiesen
            werden, bzw. die aufgeführten Änderungen des Income nicht der
            letzten Änderung entsprechen.
          </v-banner>

          <v-timeline dense clipped>
            <v-timeline-item
              fill-dot
              class="white--text"
              color="primary"
              large
              v-for="(item, index) in timeline"
              :key="item.snap"
            >
              <template v-slot:icon>
                <span>{{ item.snap }}</span>
              </template>
              <table class="timeline-table">
                <thead
                 
                >
                  <tr>
                    <th>
                      <span>{{ snapshotInfo(item.snap) }}</span>
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width:80%">
                      <ul
                        v-if="
                          index === 0 &&
                          ((!snapshot_id && !moment) ||
                            fc.snapshots[0].id === snapshot_id)
                        "
                        class="changelog"
                      >
                        <li v-for="row in item.sumsByGroup" :key="row.group_id">
                          <span
                            class="label"
                            @click="nav(changes_by, row.group_id)"
                            v-html="row.label"></span
                          >
                          
                          <span class="right"></span>
                          <span class="right" style="opacity: 0.67">
                            {{ toLocale(row.sum) }} </span
                          >
                        </li>
                      </ul>
                      <ul
                        v-else-if="item.deltaByGroup.length > 0"
                        class="changelog"
                      >
                        <li
                          v-for="row in item.deltaByGroup"
                          :key="row.group_id"
                        >
                          <span
                            class="label"
                            @click="nav(changes_by, row.group_id)"
                            v-html="row.label"></span
                          >
                          <span
                            class="right"
                            v-html="coloredToLocale(row.delta)"
                          ></span>
                          <span class="right" style="opacity: 0.67">
                            {{ toLocale(row.sum) }} </span
                          >
                        </li>
                      </ul>

                      <div style="padding: 0 1em; opacity: 0.5" v-else>
                        {{ trl("changelog_no_changes") }}
                      </div>
                    </td>
                    <td class="totals" valign="bottom">
                      <div v-if="snapshot_id || moment">
                        <v-icon
                          size="12"
                          v-for="s in status"
                          :key="s"
                          :color="
                            $store.state.budget.status.find(
                              (obj) => obj.value === s
                            ).color
                          "
                          >{{
                            $store.state.budget.status.find(
                              (obj) => obj.value === s
                            ).icon
                          }}</v-icon
                        >
                      </div>
                      <div
                        v-if="
                          index === 0 &&
                          ((!snapshot_id && !moment) ||
                            fc.snapshots[0].id === snapshot_id)
                        "
                      >
                        <v-icon
                          size="12"
                          v-for="s in config.status"
                          :key="s"
                          :color="
                            $store.state.budget.status.find(
                              (obj) => obj.value === s
                            ).color
                          "
                          >{{
                            $store.state.budget.status.find(
                              (obj) => obj.value === s
                            ).icon
                          }}</v-icon
                        >
                      </div>
                      <div v-else v-html="coloredToLocale(item.delta)"></div>
                      <div>{{ toLocale(item.sum) }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-timeline-item>
          </v-timeline>
         
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="mx-6">
          <v-col cols="4">
            <v-select
              v-model="config.field"
              :items="$store.state.budget.fields.filter(f => f.sumup)"
              item-value="key"
              item-text="label"
              label="betrachtetes Budget"
              outlined
              dense
              @change="lookat"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <StatusSelector @change="statuschange" :status="status" />
            
          </v-col>
          <v-col cols="2">
            <v-btn text @click="dialog = false">
              {{ trl("close") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { trl, getDivisionName, getClientName, getAttributeName, numerus, smartjoin } from "@/utils/strings";
import { toLocale, coloredToLocale } from "@/utils/numbers";
import { d2s } from "@/utils/dates";
import { getClientFamily } from "@/utils/clients";
import StatusSelector from "@/components/StatusSelector";



export default {
  name: "Changelog",
  props: [
    "by",
    "value",
    "within",
    "withinValue",
    "status",
    "field",
    "data",
    "fcid",
    "snapshot_id",
    "moment",
    "first",
    "last",
    "useClientGroups"
  ],
  computed: {
    ...mapGetters([
      "current",
      "clients",
      "divisions",
      "userClients",
      "userDivisions",
      "forecasts",
      "attributes",
    ]),
  },
  data() {
    return {
      dialog: false,
      title: "",
      loading: true,
      display_by: null,
      changes_by: null,
      snapshots: [],
      with_live: true,
      timeline: [],
      transfers: [],
      config: {
        data: [],
        by: null,
        value: null,
      },
      fc: null
    };
  },
  methods: {
    trl,
    getDivisionName,
    getClientName,
    getAttributeName,
    toLocale,
    coloredToLocale,
    setGroupBy() {
      switch (this.config.by) {
        case "division_id":
          this.changes_by =
            this.within ? "project_id" : "client_id";
          break;
        case "client_id":
          this.changes_by =
            this.within ? "project_id" : "division_id";
          break;
        case "attribute_id":
          this.changes_by = "division_id"; // oder selbst switchen zur client_id? => TODO!
      }
    },
    setPieChartLabel(snap){

      let label = "";

      switch (this.config.by) {
        case "division_id":
          label += this.getDivisionName(this.config.value);
          break;
        case "client_id":
          label += this.getClientName(this.config.value);
          break;
        case "attribute_id":
          label += this.getAttributeName(this.config.value);
          break;
      }

      label += ' ' + snap;
      return label;
    },
    setTitle() {
      this.title = 'Timeline "';

      switch (this.config.by) {
        case "division_id":
          this.title += this.getDivisionName(this.config.value);
          break;
        case "client_id":
          this.title += this.getClientName(this.config.value);
          break;
        case "attribute_id":
          this.title += this.getAttributeName(this.config.value);;
          break;
      }

      if (this.within) {

        this.title += " » ";

        switch (this.within) {
          case "division_id":
          this.title += this.getDivisionName(this.withinValue);
          break;
        case "client_id":
          this.title += this.getClientName(this.withinValue);
          break;
        case "attribute_id":
          this.title += this.getAttributeName(this.withinValue);;
          break;
        }
      }

      this.title += '" - ';
      this.title += ` ${trl("budget_" + this.config.field)}`;
    },
    async init() {
      this.fc = this.fcid
        ? this.forecasts.find((fc) => fc.id == this.fcid)
        : this.forecasts.filter((fc) => fc.active)[0];

      this.with_live = this.fc.active || this.moment ? true : false;

        const params = {
          tenant: this.current.id,
          fcid: this.fc.id,
          with_live: this.with_live,
        };

        if (this.config.by === "division_id") {
          params.division = this.config.value;
        } else if (this.config.by === "client_id") {
          params.client = this.useClientGroups ? getClientFamily(this.config.value) : this.config.value;
        }

        if (this.within) {
          if (this.within === "division_id") {
            params.division = this.withinValue;
          } else if (this.within === "client_id") {
            params.client = this.useClientGroups ? getClientFamily(this.withinValue) : this.withinValue;
          }
        }

        params.by = this.by;
        //params.nocache = true; ??? warum wollte ich den cache hier mal ausschalten?

        const data = await this.$store.dispatch("req", {
          route:
            "progress?" +
            Object.keys(params)
              .map((key) => key + "=" + params[key])
              .join("&"),
        });

        this.config.data = data.sort((a, b) => a.sort - b.sort);


        const transfers = await this.$store.dispatch("req", {
          route:
            "transfers?" +
            Object.keys(params)
              .map((key) => key + "=" + params[key])
              .join("&"),
        });

        this.transfers = transfers



      this.loading = false;

      //this.snapshots = [...new Set(this.config.data.map((x) => x.snapshot))];

      let relevantSnaps = this.fc.snapshots;
      if (this.first) {
        relevantSnaps = relevantSnaps.filter(s => s.id >= this.first)
      }

      if (this.last) {
        relevantSnaps = relevantSnaps.filter(s => s.id <= this.last)
      }


      if (this.config.by === "division_id") {
        this.snapshots = relevantSnaps
          .filter(
            (s) =>
              s.closed_at ||
              s.commits.map((c) => c.division_id).includes(this.config.value)
          )
          .map((s) => s.title);
      } else if (this.within === "division_id") {
        this.snapshots = relevantSnaps
          .filter(
            (s) =>
              s.closed_at ||
              s.commits.map((c) => c.division_id).includes(this.withinValue)
          )
          .map((s) => s.title);
      } else {
        this.snapshots = relevantSnaps
          .filter((s) => s.closed_at)
          .map((s) => s.title);
      }


      if (!this.last && this.with_live){
        this.snapshots.push("live");
      }
      this.timeline = this.getRelevantData();

      if (this.snapshot_id) {
        let snapshotTitle = this.fc.snapshots.find(
          (s) => s.id === this.snapshot_id
        ).title;
        this.timeline = this.timeline.filter((t) => t.snap === snapshotTitle);
      } else if (this.moment) {
        let snapshotTitle = "live";
        this.timeline = this.timeline.filter((t) => t.snap === snapshotTitle);
      }
    },
    statuschange(newStatus){
      this.config.status = newStatus;
      this.lookat();
    },
    getRelevantData() {
      let subset = this.config.data.filter(
        (item) =>
          item[this.config.by] === this.config.value &&
          this.config.status.indexOf(item.status) > -1
      );

      if (this.useClientGroups && this.config.by === "client_id") {
        subset = this.config.data.filter(
        (item) =>
          getClientFamily(this.config.value).includes(item[this.config.by]) &&
          this.config.status.indexOf(item.status) > -1
      );
      }

      let groups = [...new Set(subset.map((x) => x[this.changes_by]))]; // z.B. Kunden-Ids, wenn die Entwicklung einer Unit betrachtet wird, oder Unit_ids bei der Betrachtung von Kunden

      let sums = [];

      this.snapshots.forEach((s, index) => {
        let sumsByGroup = [];

        groups.forEach((g) => {
          let subsetGroup = subset.filter(
            (row) => row.snapshot === s && row[this.changes_by] === g
          );

          if (index === 0 && subsetGroup.length === 0) return;

          let sumOfGroup = subsetGroup.reduce(
            (accumulator, current) => accumulator + current[this.config.field],
            0
          );

          let label = this.getLabel(g);

          

          sumsByGroup.push({
            group_id: g,
            label: label,
            sum: sumOfGroup,
          });
        });

        let sum = sumsByGroup.reduce(
          (accumulator, current) => accumulator + current.sum,
          0
        );

        if (index === 0) {
          sums.push({
            snap: s,
            sum: sum,
            delta: 0,
            deltaByGroup: [],
            sumsByGroup: sumsByGroup, //sumsByGroup.filter(x => x.sum !== 0)
          });
          return;
        }

        let delta = sum - sums[index - 1].sum;
        let deltaByGroup = [];

        groups.forEach((g) => {
          let sbg_current = sumsByGroup.find((x) => x.group_id === g)
            ? sumsByGroup.find((x) => x.group_id === g).sum
            : 0;
          let sbg_prev = sums[index - 1].sumsByGroup.find(
            (x) => x.group_id === g
          )
            ? sums[index - 1].sumsByGroup.find((x) => x.group_id === g).sum
            : 0;

          if (sbg_current === sbg_prev) return;

          deltaByGroup.push({
            group_id: g,
            label: this.getLabel(g, s),
            transferred: true,
            delta: sbg_current - sbg_prev,
            sum: sbg_current,
          });
        });

        sums.push({
          snap: s,
          sum: sum,
          delta: delta,
          sumsByGroup: sumsByGroup,
          deltaByGroup: deltaByGroup,
        });
      });

      return sums;
    },
    getLabel(id, snapshot) {
      switch (this.changes_by) {
        case "client_id":
          return this.getClientName(id) + this.getTransfer(id, snapshot);
        case "division_id":
          return this.getDivisionName(id) ;
        case "project_id":
          let budgetRow = this.config.data
            .filter((item) => item.project_id === id)
            .pop();
          return (
            '<i class="mdi ' + this.$store.state.budget.status[budgetRow.status].icon +'" style="color:' + this.$store.state.budget.status[budgetRow.status].hex +'"></i>&nbsp;&nbsp;' +  
            budgetRow.title +
            " – " +
            this.getAttributeName(budgetRow.attribute_id) // + this.getTransferProject(budgetRow)
          );
      }
    },
    getTransfer(id, snapshot){
      let t = this.transfers.filter(t => t.snapshot === snapshot && t.client.client_id === id);
      if (!t.length) return "";

      let icon, color, theOther, sum, tt = '';
      if (t[0].from.division_id === this.config.value) {
        icon = "transfer-right";
        color = "red";
        theOther = smartjoin(t.map(t => t.to.name), trl('and'));
      } else {
        icon = "transfer-left";
        color = "green";
        theOther = smartjoin(t.map(t => t.from.name), trl('and'));
      }

      t.forEach(t => {
        sum = t.budget.reduce((acc, cur) => acc + cur[this.config.field], 0);
        tt+= `Budget: ${toLocale(sum)} (${numerus('? Kampagne|n', t.budget.length)}) am ${d2s(t.transfer_date, 'dateshort')} `;

      })

      //let tt = "Transfer " + t[0].from.name + " » " + t[0].to.name + ": " + toLocale(sum);
      
      return `<strong style="font-weight:normal;color:${color}" title="${tt}">&thinsp;<i class="mdi mdi-${icon}"></i>&thinsp;${theOther}</strong>`;
    },
    getTransferProject(budget, snapshot){
      console.log("budgetRow");
      console.log(budget);

    },
    nav(by, value) {
      if (this.moment || this.snapshot_id) return;

      this.loading = true;
      this.config.by = by;
      this.config.value = value;
      this.setGroupBy();
      this.setTitle();

      setTimeout(() => {
        this.init();
      }, 500);
    },
    snapshotInfo(title) {

      if (title === 'live') return;

      let snapshots = this.forecasts.find((f) => f.id === this.fcid).snapshots;
      let snapshot = snapshots.find((s) => s.title === title);

      if (!snapshot) {
        return title;
      }

      if (this.config.by === "division_id") {
        let info = snapshot.commits.filter(
          (s) => s.division_id === this.config.value
        );

        if (info.length === 0) return "---";

        return `gemeldet am ${d2s(info[0].created_at, "short")} von ${
          info[0].created_by
        }`;
      }

      if (this.within === "division_id") {
        let info = snapshot.commits.filter(
          (s) => s.division_id === this.withinValue
        );

        if (info.length === 0) return "---";

        return `gemeldet am ${d2s(info[0].created_at, "short")} von ${
          info[0].created_by
        }`;
      }

      return '---'; //snapshot;
    },
    snapshotData(snapTitle) {
      return this.config.data.filter(
        (d) =>
          d[this.config.by] === this.config.value && d.snapshot == snapTitle
      );
    },
    lookat() {
      this.setGroupBy();
      this.setTitle();
      this.timeline = this.getRelevantData();
    },
  },
  components: { StatusSelector },
  watch: {
    dialog: function (val) {
      this.loading = true;
      if (!val) return;

      (this.config = {
        data: [],
        by: this.by,
        value: this.value,
        field: this.field,
        status: this.status,
      }),
        this.setGroupBy();
      this.setTitle();
      this.init();
    },
  },
};
</script>

<style lang="scss">
.timeline-table {
  color: #000;
  background-color: #f5f5f5;
  width: 100%;

th {
    text-align: left;
    background-color: #ddd;
    color: #000;
    font-size: 11px;
    padding: 0.1em 1.25em;
  }

  td {
    min-width: 120px;
  }

td.totals {
  vertical-align: bottom;
  font-weight: bold;
  padding-right: 1em;
  text-align: right;
  border-left: 1px solid #fff;
}

th.has-pie .pie {
  width: 14px;
  height: 14px;
  margin-bottom: -2px;
  border: none;
  box-shadow: none;
}


}
  

.changelog {
  margin: 0 0 0 0;

  padding: 0;
  font-size: 13px;
  display: table;
  border-collapse: collapse;

  li {
    display: table-row;

    span {
      display: table-cell;
      padding: 0em 1em;
      margin: 0;
      cursor: default;

      &.label {
        width: 400px;
      }

      &.right {
        text-align: right;

        span{
          display:inline;
        }

      }
    }

    &:hover {
      background-color: #f0f0f0;
    }

    &:last-child span {
      border-bottom: none;
    }
  }

}








</style>