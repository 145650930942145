var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"860px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 primary white--text mb-6"},[_c('small',[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',[(_vm.loading)?_c('div',{staticClass:"text-center",staticStyle:{"height":"400px","padding-top":"160px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary lighten-4","size":"100","width":"10"}})],1):_vm._e(),(!_vm.loading)?_c('v-container',[(_vm.moment)?_c('v-banner',{staticClass:"mb-2",attrs:{"color":"warning"}},[_c('p',[_c('strong',[_vm._v("Die Budgetzahlen sind in Bearbeitung und können sich bis zur Meldung noch ändern!")])]),_vm._v(" Die letzte Änderung muss sich auch nicht zwangsläufig auf das Income ausgewirkt haben, und kann z.B. nur einen Kommentar o.ä. betreffen. Daher ist es durchaus möglich, dass keine Änderungen ausgewiesen werden, bzw. die aufgeführten Änderungen des Income nicht der letzten Änderung entsprechen. ")]):_vm._e(),_c('v-timeline',{attrs:{"dense":"","clipped":""}},_vm._l((_vm.timeline),function(item,index){return _c('v-timeline-item',{key:item.snap,staticClass:"white--text",attrs:{"fill-dot":"","color":"primary","large":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('span',[_vm._v(_vm._s(item.snap))])]},proxy:true}],null,true)},[_c('table',{staticClass:"timeline-table"},[_c('thead',[_c('tr',[_c('th',[_c('span',[_vm._v(_vm._s(_vm.snapshotInfo(item.snap)))])]),_c('th',[_vm._v(" ")])])]),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"80%"}},[(
                        index === 0 &&
                        ((!_vm.snapshot_id && !_vm.moment) ||
                          _vm.fc.snapshots[0].id === _vm.snapshot_id)
                      )?_c('ul',{staticClass:"changelog"},_vm._l((item.sumsByGroup),function(row){return _c('li',{key:row.group_id},[_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(row.label)},on:{"click":function($event){return _vm.nav(_vm.changes_by, row.group_id)}}}),_c('span',{staticClass:"right"}),_c('span',{staticClass:"right",staticStyle:{"opacity":"0.67"}},[_vm._v(" "+_vm._s(_vm.toLocale(row.sum))+" ")])])}),0):(item.deltaByGroup.length > 0)?_c('ul',{staticClass:"changelog"},_vm._l((item.deltaByGroup),function(row){return _c('li',{key:row.group_id},[_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(row.label)},on:{"click":function($event){return _vm.nav(_vm.changes_by, row.group_id)}}}),_c('span',{staticClass:"right",domProps:{"innerHTML":_vm._s(_vm.coloredToLocale(row.delta))}}),_c('span',{staticClass:"right",staticStyle:{"opacity":"0.67"}},[_vm._v(" "+_vm._s(_vm.toLocale(row.sum))+" ")])])}),0):_c('div',{staticStyle:{"padding":"0 1em","opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.trl("changelog_no_changes"))+" ")])]),_c('td',{staticClass:"totals",attrs:{"valign":"bottom"}},[(_vm.snapshot_id || _vm.moment)?_c('div',_vm._l((_vm.status),function(s){return _c('v-icon',{key:s,attrs:{"size":"12","color":_vm.$store.state.budget.status.find(
                            (obj) => obj.value === s
                          ).color}},[_vm._v(_vm._s(_vm.$store.state.budget.status.find( (obj) => obj.value === s ).icon))])}),1):_vm._e(),(
                        index === 0 &&
                        ((!_vm.snapshot_id && !_vm.moment) ||
                          _vm.fc.snapshots[0].id === _vm.snapshot_id)
                      )?_c('div',_vm._l((_vm.config.status),function(s){return _c('v-icon',{key:s,attrs:{"size":"12","color":_vm.$store.state.budget.status.find(
                            (obj) => obj.value === s
                          ).color}},[_vm._v(_vm._s(_vm.$store.state.budget.status.find( (obj) => obj.value === s ).icon))])}),1):_c('div',{domProps:{"innerHTML":_vm._s(_vm.coloredToLocale(item.delta))}}),_c('div',[_vm._v(_vm._s(_vm.toLocale(item.sum)))])])])])])])}),1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-row',{staticClass:"mx-6"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.$store.state.budget.fields.filter(f => f.sumup),"item-value":"key","item-text":"label","label":"betrachtetes Budget","outlined":"","dense":""},on:{"change":_vm.lookat},model:{value:(_vm.config.field),callback:function ($$v) {_vm.$set(_vm.config, "field", $$v)},expression:"config.field"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('StatusSelector',{attrs:{"status":_vm.status},on:{"change":_vm.statuschange}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.trl("close"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }